span.data-grid-container,
span.data-grid-container:focus {
  outline: none;
}

.data-grid-container .data-grid {
  table-layout: fixed;
  border-collapse: collapse;
}

.data-grid-container .data-grid .cell.updated {
  background-color: rgba(0, 145, 253, 0.16);
  transition: background-color 0ms ease;
}
.data-grid-container .data-grid .cell {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: cell;
  transition: background-color 500ms ease;
  vertical-align: middle;
}
.data-grid-container .data-grid .align-text-top.cell {
  vertical-align: text-top !important;
}
.data-grid-container .data-grid .align-text-top.cell span {
  margin-top: 0.9rem;
}
.data-grid-container .data-grid .cell.selected {
  border: 1px double rgb(33, 133, 208);
  transition: none;
  box-shadow: inset 0 -100px 0 rgba(33, 133, 208, 0.15);
}

.data-grid-container .data-grid .cell > .text {
  padding: 2px 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.data-grid-container .data-grid .cell > input {
  outline: none !important;
  border: 2px solid rgb(33, 133, 208);
  text-align: right;
  width: calc(100% - 6px);
  height: 11px;
  background: none;
  display: block;
}

.data-grid-container .data-grid.nowrap .cell,
.data-grid-container .data-grid.nowrap .cell.nowrap,
.data-grid-container .data-grid .cell.nowrap,
.data-grid-container .data-grid.wrap .cell.nowrap,
.data-grid-container .data-grid.clip .cell.nowrap {
  white-space: nowrap;
  overflow-x: visible;
}

.data-grid-container .data-grid.clip .cell,
.data-grid-container .data-grid.clip .cell.clip,
.data-grid-container .data-grid .cell.clip,
.data-grid-container .data-grid.wrap .cell.clip,
.data-grid-container .data-grid.nowrap .cell.clip {
  white-space: nowrap;
  overflow-x: hidden;
}

.data-grid-container .data-grid .cell .value-viewer,
.data-grid-container .data-grid .cell .data-editor {
  display: block;
}

.data-grid-container .data-grid .whitespace-nowrap .value-viewer,
.data-grid-container .data-grid .whitespace-nowrap .data-editor {
  white-space: pre;
  width: 20px;
}

.writing-vertical {
  writing-mode: vertical-rl;
}

.title-vertical span {
  position: absolute;
  top: 30vw;
  left: 0.2rem;
  height: 100%;
}

.title-vertical2 span {
  position: absolute;
  top: 18vw;
  left: 0.2rem;
  height: 100%;
}

.crosshead-vertical span {
  position: absolute;
  top: 22vw;
  left: 1.2rem;
  height: 100%;
}

.crosshead-vertical2 span {
  position: absolute;
  top: 7vw;
  left: 1.2rem;
  height: 100%;
}

.crosshead-indent {
  margin-left: 1.2rem;
}

.data-grid-container .data-grid .cell > input {
  width: 100%;
  height: auto !important;
}

.top_absolute {
  top: 1rem;
}

.btn_absolute {
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: auto;
}

.table_pt {
  padding-top: 1.25rem;
}

.table_pb {
  padding-bottom: 1.25rem;
}

.note_h {
  height: 10rem;
  vertical-align: top !important;
}

.table_header_w {
  min-width: 23.75rem;
}

.table_up_w {
  width: 4rem;
}

.table_per_w {
  width: 85%;
}

.table_indent {
  margin-left: 1.875rem;
}

.bg_table_total::before {
  background-color: #80b0f466;
}

.datasheet-input::before {
  z-index: -10 !important;
}

.datasheet-masktitle {
  z-index: 20 !important;
}
.description {
  word-break: break-all;
}
.description input {
  text-align: left !important;
}

.data-editor {
  ime-mode: inactive;
}
