@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-DEFALULT;
  }
}

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [dir="rtl"] select {
    background-position: left 0.5rem center;
  }
}

body {
  overflow-x: hidden;
}

.bg-hero::before {
  background: transparent
    linear-gradient(178deg, #DFEBFD 0%, #DFEBFD 74%, #B6D4F4 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
}

.lp-arrow {
  width: 251px;
  height: 49px;
  transform: matrix(1, 0.09, -0.09, 1, 0, 0);
  background: transparent
    linear-gradient(83deg, #9fc5fa66 0%, #6daae7 65%, #6fb3e3 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
}

.loading {
  background-color: #33333333;
}

.hovertext {
  position: absolute;
}

.hovertext:before {
  content: attr(data-hover);
  width: 180px;
  visibility: hidden;
  opacity: 0;
  background-color: #1f1f1f;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border-radius: 8px;
  padding: 4px 8px;
  transition: opacity 0.5s ease-in-out;

  position: absolute;
  z-index: 9999;
  left: 20px;
  top: 20px;
}

.hovertext:hover:before {
  opacity: 0.9;
  visibility: visible;
}
